/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Row, Col} from 'reactstrap'

import Header from '../components/header'
//import Footer from '../components/footer'
import Sidebar from '../components/Sidebar'
import '../styles/index.scss'


const Layout = ({ author, authorImageFluid, children , pageTitle}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

    var footer = (<div className="site-footer">
    <p className="text-center">Follow me on social media</p>
    <div className="footer-social-links">
    
    <ul className="social-links-list">
            <li>
                <a href="https://www.facebook.com/LOnWheels.us" target="_blank" rel="noopener noreferrer" className="facebook">
                    <i className="fab fa-facebook fa-2x"/>
                </a>
            </li>
            <li>
                <a href="https://twitter.com/L_OnWheels" target="_blank" rel="noopener noreferrer" className="twitter">
                    <i className="fab fa-twitter fa-2x"/>
                </a>
            </li>
            <li>
                <a href="https://www.instagram.com/l__onwheels" target="_blank" rel="noopener noreferrer" className="instagram">
                    <i className="fab fa-instagram fa-2x"/>
                </a>
            </li>
            <li>
                <a href="https://www.youtube.com/channel/UCjH790vqyWOvjeYPNLz3QJw" target="_blank" rel="noopener noreferrer" className="youtube">
                    <i className="fab fa-youtube fa-2x"/>
                </a>
            </li>
            
        </ul>
    </div>
    <p className="text-right">© {new Date().getFullYear()} Elizabeth Blackmore - (L OnWheels)</p>
    
</div>)

  return (
    <>
    <link
      rel="stylesheet"
      href="https://use.fontawesome.com/releases/v5.6.3/css/all.css"
      crossOrigin="anonymous"
      />
      <script src="https://kit.fontawesome.com/8eda850ed2.js"></script>
      <link rel="script" href="https://apis.google.com/js/platform.js" crossOrigin="anonymous"/>
      <Header siteTitle={data.site.siteMetadata.title} />
      
      <div className="container" id="content">
        <h1>{pageTitle}</h1>
    <Row>
      <Col md="8">
         {children}
      
      </Col>
      <Col md="4">
        <Sidebar author={author}/>
      </Col>
    </Row>
    </div>
      
      
      {footer}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

/*
<Header siteTitle={data.site.siteMetadata.title} />

© {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>
*/