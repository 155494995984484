import React from 'react'
import {Card, CardTitle, CardBody} from 'reactstrap'
import VideoPlayer from './VideoPlayer'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

const PostCard = ({node}) => {
    
    if(node.frontmatter.type === "hidden")
    {
        return(
             null
        )
    }

    var image = <div></div>
    var link = <Link to={node.fields.slug}>{node.frontmatter.title}</Link>

    if(node.frontmatter.image.name === "empty")
    {
        image = <p></p>
    }

    if(node.frontmatter.type === "video")
    {
        var videoID = node.frontmatter.videoID
        image = VideoPlayer({
            videoID:{videoID},
            t_width:"100%",
            t_height:"200",
            tframeborder:"0"})
    }
    else{
        image = <Link to={node.fields.slug}><Img className="card-image-top" fluid={node.frontmatter.image.childImageSharp.fluid}/></Link>
    }
    
    return(
        <Card key={node.id}>
            {image}
            <CardBody>
                <CardTitle>
                    {link}
                </CardTitle>
            </CardBody>
        </Card>
    )
}

export default PostCard