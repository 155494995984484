import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/layout';
import Post from '../components/Post'
import { titleCase } from '../util/titleCase'

const tagPost = ({ data, pageContext}) => {

    const tag = titleCase(pageContext.tag)
    const { totalCount } = data.allMarkdownRemark

    const pageHeader = `${totalCount} post${(totalCount === 1) ? '' : 's'} tagged with "${tag}"`

    return(
        <Layout pageTitle={pageHeader}>
            {data.allMarkdownRemark.edges.map(({node}) =>
            <Post key={node.id}
            title={node.frontmatter.title}
            slug={node.fields.slug}
            date={node.frontmatter.date}
            body={node.frontmatter.body}
            fluid={node.frontmatter.image.childImageSharp.fluid}
            imageName={node.frontmatter.image.name}
            type={node.frontmatter.type}
            videoID={node.frontmatter.videoID}
            tags={node.frontmatter.tags}></Post>
            )}
        </Layout>
    )
    
}

export const tagQuery = graphql`
    query($tag: String!){
        allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC}
            filter: {frontmatter: {tags: {in: [$tag]}, type: { ne: "hidden"}}}
        ){
            totalCount
            edges{
                node{
                    id
                    frontmatter{
                        title
                        date(formatString: "MMM DD YYYY")
                        tags
                        videoID
                        type
                        image{
                            name
                            childImageSharp{
                                fluid(maxWidth: 650){
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    fields{
                        slug
                    }
                    excerpt
                }
            }
        }
    }
`


export default tagPost