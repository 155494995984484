import React from 'react'
import { Link } from 'gatsby'
import {Badge, Card, CardTitle, CardText, CardBody} from 'reactstrap'
import Img from 'gatsby-image'
import { slugify, getStylefromString, fixNames } from '../util/utilityFunctions'
import VideoPlayer from '../components/VideoPlayer'
import '../styles/index.scss'

const Post = ({title, slug, date, body, fluid, tags, imageName, type, videoID, links=true}) => {

    if(type === "hidden")
    {
        return(
             null
        )
    }

    var image = <Link to={slug}><Img className="card-image-top" fluid={fluid}/></Link>
    var readMore = <Link to={slug} className="btn btn-outline-danger float-right text-uppercase">Read More</Link>
    var cardTitle = <CardTitle><Link to={slug}>{title}</Link><span className="text-info float-right">{date}</span></CardTitle>

    
    if(links !== true){
        image = <Img className="card-image-top" fluid={fluid}/>
        readMore = ""
        cardTitle = <CardTitle>{title} <span className="text-info float-right">{date}</span></CardTitle>
    }

    if(imageName === "empty")
    {
        image = <div></div>
    }

    
/*    if(body.length < 137)
    {
        if(imageName !== "empty")
        {
            image = <Img className="card-image-top" fluid={fluid}/>
        }
        readMore = <div></div>
        cardTitle = <CardTitle>{title} <span className="text-info float-right">{date}</span></CardTitle>
    }
*/
    if(type === "video")
    {
        //image = cardTitle
        //cardTitle = <div dangerouslySetInnerHTML={{__html: html}}/>
        image = VideoPlayer({
            videoID:{videoID},
            t_width:"100%",
            t_height:"500",
            tframeborder:"0"})
        //<div dangerouslySetInnerHTML={{__html: html}}/>
    }

    let _tags = []

    tags.map(tag => {

        var style = getStylefromString(tag);



        _tags.push(
            <Link to={`/tags/${slugify(tag)}`}>
                <Badge color={style} className="text-uppercase" pill>{fixNames(tag)}</Badge>
            </Link>
        )
        
    });

return(
    <Card>
            <CardBody>
            {cardTitle}
                {image}
                <CardText>{body}</CardText>
                <h6>
                    {_tags}
                    </h6>
                {readMore}
            </CardBody>
        </Card>
)
    /*
    if(imageName === "empty")
    {
        if(type === "video")
        {
            if(body.length < 137){
                return(
                    <Card>
                    <CardBody>
                    <CardTitle>{title}</CardTitle>
                        <div dangerouslySetInnerHTML={{__html: html}}/>
                        <CardSubtitle>
                            <span className="text-info">{date}</span> by{' '}
                            <span className="text-info">{author}</span>
                        </CardSubtitle>
                        <CardText>{body}</CardText>
                        <ul className="post-tags">
                            {tags.map(tag => (
                                <li key={tag}>
                                    <Link to={`/tags/${slugify(tag)}`}>
                                    <Badge color="primary" className="text-uppercase">{tag}</Badge>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </CardBody>
                </Card>
                )
            }
            return(
                <Card>
                <CardBody>
                    <CardTitle><Link to={slug}>{title}</Link></CardTitle>
                    <div dangerouslySetInnerHTML={{__html: html}}/>
                    <CardSubtitle>
                        <span className="text-info">{date}</span> by{' '}
                        <span className="text-info">{author}</span>
                    </CardSubtitle>
                    <CardText>{body}</CardText>
                    <ul className="post-tags">
                        {tags.map(tag => (
                            <li key={tag}>
                                <Link to={`/tags/${slugify(tag)}`}>
                                <Badge color="primary" className="text-uppercase">{tag}</Badge>
                                </Link>
                            </li>
                        ))}
                    </ul>
                    <Link to={slug} className="btn btn-outline-primary float-right text-uppercase">
                        Read More
                    </Link>
                </CardBody>
            </Card>
            )
        }
        return(
            <Card>
                <CardBody>
                    <CardTitle><Link to={slug}>{title}</Link></CardTitle>
                    <CardSubtitle>
                        <span className="text-info">{date}</span> by{' '}
                        <span className="text-info">{author}</span>
                    </CardSubtitle>
                    <CardText>{body}</CardText>
                    <ul className="post-tags">
                        {tags.map(tag => (
                            <li key={tag}>
                                <Link to={`/tags/${slugify(tag)}`}>
                                <Badge color="primary" className="text-uppercase">{tag}</Badge>
                                </Link>
                            </li>
                        ))}
                    </ul>
                    <Link to={slug} className="btn btn-outline-primary float-right text-uppercase">
                        Read More
                    </Link>
                </CardBody>
            </Card>
        )
    }
    if(body.length < 137){
        return(
                
            <Card>
                <CardBody>
                    <CardTitle>{title}</CardTitle>
                <Img className="card-image-top" fluid={fluid}/>
                
                    <CardSubtitle>
                        <span className="text-info">{date}</span> by{' '}
                        <span className="text-info">{author}</span>
                    </CardSubtitle>
                    <CardText>{body}</CardText>
                    <ul className="post-tags">
                        {tags.map(tag => (
                            <li key={tag}>
                                <Link to={`/tags/${slugify(tag)}`}>
                                <Badge color="primary" className="text-uppercase">{tag}</Badge>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </CardBody>
            </Card>
        )
    }
    return(
        <Card>
            <CardBody>
            <Link to={slug}>
            <Img className="card-image-top" fluid={fluid}/>
            </Link>
                <CardTitle><Link to={slug}>{title}</Link></CardTitle>
                <CardSubtitle>
                    <span className="text-info">{date}</span> by{' '}
                    <span className="text-info">{author}</span>
                </CardSubtitle>
                <CardText>{body}</CardText>
                <ul className="post-tags">
                    {tags.map(tag => (
                        <li key={tag}>
                            <Link to={`/tags/${slugify(tag)}`}>
                            <Badge color="primary" className="text-uppercase">{tag}</Badge>
                            </Link>
                        </li>
                    ))}
                </ul>
                <Link to={slug} className="btn btn-outline-primary float-right text-uppercase">
                    Read More
                </Link>
            </CardBody>
        </Card>
    )*/
}

export default Post