import React from 'react'

const VideoPlayer = ({ videoID, t_width, t_height, tframeborder} ) => {
    var source = "https://www.youtube.com/embed/"+videoID


    return (
        <p className="text-align-center">
            <iframe title={videoID} width={t_width} height={t_height} src={source} frameBorder={tframeborder} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </p>
    )
}

export default VideoPlayer