import React from 'react'
import {Card, CardTitle, CardText, CardBody, Form, FormGroup, Input, Col} from 'reactstrap'
import Button from 'reactstrap/lib/Button';
import { graphql, StaticQuery} from 'gatsby';
import PostCard from './PostCard'
import LizzieImage from '../images/lizzie.png'


//<Img className="card-image-top" fluid={authorFluid}/>

/*
<div id="build-sample" style="padding: 8px; background: rgb(255, 255, 255);"><div style="text-indent: 0px; margin: 0px; padding: 0px; background: transparent; border-style: none; float: none; line-height: normal; font-size: 1px; vertical-align: baseline; display: inline-block; width: 167px; height: 48px;" id="___ytsubscribe_7"><iframe ng-non-bindable="" frameborder="0" hspace="0" marginheight="0" marginwidth="0" scrolling="no" style="position: static; top: 0px; width: 167px; margin: 0px; border-style: none; left: 0px; visibility: visible; height: 48px;" tabindex="0" vspace="0" width="100%" id="I10_1565127353206" name="I10_1565127353206" src="https://www.youtube.com/subscribe_embed?usegapi=1&amp;channelid=UCjH790vqyWOvjeYPNLz3QJw&amp;layout=full&amp;count=default&amp;origin=https%3A%2F%2Fgoogle-developers.appspot.com&amp;gsrc=3p&amp;ic=1&amp;jsh=m%3B%2F_%2Fscs%2Fapps-static%2F_%2Fjs%2Fk%3Doz.gapi.en.y5hhWjOdu80.O%2Fam%3DwQE%2Fd%3D1%2Frs%3DAGLTcCNsKzxWFhezE2KkFP7auTmVDBiNEQ%2Fm%3D__features__#_methods=onPlusOne%2C_ready%2C_close%2C_open%2C_resizeMe%2C_renderstart%2Concircled%2Cdrefresh%2Cerefresh&amp;id=I10_1565127353206&amp;_gfid=I10_1565127353206&amp;parent=https%3A%2F%2Fgoogle-developers.appspot.com&amp;pfname=&amp;rpctoken=81531630" data-gapiattached="true"></iframe></div></div>
*/



const Sidebar = ({ author = "hide", social="hide", newsletter="hide", ads="hide",recent="show"}) => {

    var _author = ""
    var _social = ""
    var _newsletter = ""
    var _ads = ""
    var _recent = ""

    if(author === "show")
    {
        _author = <Card>
                <img src={LizzieImage} style={{maxWidth: '100%'}} alt="Lizzie profile"/>
                <CardBody>
                    <CardTitle className="text-center text-uppercase mb-3">Elizabeth Blackmore</CardTitle>
                    <CardText>Life Rolls and I have 8 perfect wheels to roll with it.</CardText>
                </CardBody>
            </Card>
    }

    if(social === "hide")
    {
        _social = <Card>

        <CardBody>

                    <div className="text-center">
                    <iframe title="subscribe-yt"
                    ng-non-bindable="" frameBorder="0" hspace="00" marginHeight="0" marginWidth="0" scrolling="yes"
                    tabIndex="0" vspace="0" width="100%" id="H7903" name="H7903" 
                    src="https://www.youtube.com/subscribe_embed?usegapi=1&amp;channelid=UCjH790vqyWOvjeYPNLz3QJw&amp;layout=full&amp;count=default&amp;origin=https%3A%2F%2Fgoogle-developers.appspot.com&amp;gsrc=3p&amp;ic=1&amp;jsh=m%3B%2F_%2Fscs%2Fapps-static%2F_%2Fjs%2Fk%3Doz.gapi.en.y5hhWjOdu80.O%2Fam%3DwQE%2Fd%3D1%2Frs%3DAGLTcCNsKzxWFhezE2KkFP7auTmVDBiNEQ%2Fm%3D__features__#_methods=onPlusOne%2C_ready%2C_close%2C_open%2C_resizeMe%2C_renderstart%2Concircled%2Cdrefresh%2Cerefresh&amp;id=I10_1565127353206&amp;_gfid=I10_1565127353206&amp;parent=https%3A%2F%2Fgoogle-developers.appspot.com&amp;pfname=&amp;rpctoken=81531630"
                    data-gapiattached="true"></iframe>
                    </div>

        </CardBody>
    </Card>
    }

    if(newsletter === "show")
    {
        _newsletter = <Card>
        <CardBody>
            <CardTitle className="text-center text-uppercase mb-3">
                Newsletter
            </CardTitle>
            <Form className="text-center">
                <FormGroup>
                    <Input type="email" name="email" placeholder="your email address..."/>
                </FormGroup>
                <Button className="btn btn-success text-uppercase">Subscribe</Button>
            </Form>
        </CardBody>
    </Card>
    }
	/*
    if(ads === "show"){
        _ads = <Card>
        <CardBody>
            <CardTitle className="text-center text-uppercase">Advertisements</CardTitle>
        </CardBody>
        <img src="https://via.placeholder.com/320x200"
        alt="Advert"
        style={{width: '100%'}}/>
    </Card>
    }
	*/

    if(recent === "show"){
        _recent = 
        <Card>
        <CardBody>
            <CardTitle className="text-center text-uppercase mb-3">
                Recent Posts
            </CardTitle>
            <StaticQuery query={sidebarQuery} render={(data) => (
                <div>
                    
                    {
                        data.allMarkdownRemark.edges.map(({node}) => (
                            PostCard({node})
                            )
                        )
                    }

                </div>
            )}/>
        </CardBody>
    </Card>
    }
    return(
        <div>
            
            {_author}
            {_social}
            {_newsletter}
            {_ads}
            {_recent}
        </div>
    )
}

const sidebarQuery = graphql`
    query sidebarQuery{
        allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC}
            limit: 3
        ){
            totalCount
            edges{
                node{
                    id
                        frontmatter{
                            title
                            type
                            videoID
                            image{
                                name
                                childImageSharp{
                                    fluid(maxWidth: 300){
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                        fields{
                            slug
                        }
                    
                }
            }
        }
    }
`

export default Sidebar