const slugify = function(text){
    return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '')
}

const styles = [
    "primary",
    "success",
    "primary",
    "dark",
    "warning",
    "dpink",
    "info",    
    "danger",
    "pink",
    
    "orange",
    "indigo",
    "purple",
    "teal",
    "lcyan",
    "dcyan",
    "lyellow",
    "lpink"
]


const getStylefromString = function(str){

    str = str.toLowerCase()
    if(str === "candi girl")
    {
        return "candigirl"
    }
    else if( str === "l onwheels" || str === "lizzie" || str === "elizabeth blackmore")
    {
        return "rosegold"
    }

    var len = str.length

    var value = 0
    for(var i = 0; i < len; i++){
        value += Math.floor(str.charCodeAt(i))
    }

    var ret = 0
    
    for(i = 0; i < value; i++)
    {
        ret += 1
        if(ret > styles.length-1)
        {
            ret = 0
        }
    }

    return styles[ret]
}

const fixNames = function(str){

    /*if ( str === "l onwheels" )
    {
        return "L OnWheels"
    }
    if ( str === "lizzie" )
    {
        return "Lizzie"
    }
    if ( str === "elizabeth blackmore" )
    {
        return "Elizabeth Blackmore"
    }*/

    return str.toUpperCase()
}

module.exports = { slugify, getStylefromString, fixNames }